import React from "react"
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import SEO from "../components/seo"
import Layout from "../modules/layout"
import Breadcrumbs from "../modules/breadcrumbs"
import PostHero from "../modules/postHero"
import Post from "../components/post"
import BlockContent from "../components/page-builder/richText"

export const query = graphql`
  query($slug: String) {
    sanityStaticPage(slug: {current: {eq: $slug}}) {
      title
      seo {
        metaTitle
        canonical
        description
        noIndex
        openGraphImage {
          asset {
            url
          }
        }
      }
      _rawBody
    }
  }
`

const StaticPage = (props) => {
  const { data } = props
  const post = data && data.sanityStaticPage
  const title = post.seo && post.seo.metaTitle ? post.seo.metaTitle : post.title
  const description = post.seo && post.seo.description ? post.seo.description : null
  const image = post.seo && post.seo.openGraphImage && post.seo.openGraphImage.asset !== null ? post.seo.openGraphImage.asset.url : null
  return (
    <>
      <Layout>
        <Helmet>
          {post.seo && post.seo.noIndex && <meta name="robots" content="noindex" />}
        </Helmet>
        <SEO
          title={title}
          description={description}
          image={image}
        />
        <PostHero title={post.title} />
        <section className="grid">
          <div className="gridItem gridItemCenter large-11">
            <Post>
              <BlockContent blocks={post._rawBody} />
            </Post>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default StaticPage
